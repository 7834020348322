import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

import globalType from './type'
// Re-export type
export const type = globalType

export const maxWidth = 1680

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const GlobalStyles = createGlobalStyle`
    /* base for rem units */
    html {
        font-size: 12px;
    }

    @media (min-width: 375px) {
        html {
            font-size: calc(12px + 4 * (100vw - 375px) / 1305);
        }
    }
    
    @media (min-width: ${maxWidth}px) {
        html {
            font-size: 16px;
        }
    }

    html,
    body {
        ${tw`m-0 p-0 w-full h-full font-body leading-tight bg-grey text-blue`};
        font-weight: normal;
        letter-spacing: -0.01em;
        /* -webkit-font-smoothing: antialiased; */
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    b, strong {
        font-weight: 700;
    }

    ul {
        list-style: disc;
        padding-left: 2rem;
    }

    ol {
        list-style: decimal;
        padding-left: 1rem;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }

    .tiny-mce {
        h3, p, ul, ol {
            &:not(:first-child) {
                margin-top: 2rem;
            }   
        }

        img {
            width: 100%;
        }

        ${globalType.body};

        h3 {
            font-size: 1.5rem;
        }

        a {
            text-decoration: underline;
            ${hoverState};
        }
    }
`

export const padding = css`
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    box-sizing: border-box;

    ${media.tab`
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    `}
`

export const margins = css`
    margin-left: 6.25rem;
    margin-right: 6.25rem;

    ${media.tab`
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    `}
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    width: ${maxWidth}px;
    max-width: 100%;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
	min-width: 14rem;
	padding: 0.85rem 2rem 1rem;
	background-color: transparent;
	border-radius: 2rem;
    ${tw`border border-blue text-blue`};
    line-height: 1;
    cursor: pointer;
    transition: all 0.2s ease;

	display: flex;
	justify-content: center;
	align-items: center;
`