import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { container, padding, bgImage } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'

import 'swiper/swiper-bundle.css'

import sliderArrow from '../../assets/images/icons/slider-arrow.svg'

const Slider = props => {
    const { slides, type } = props
    const swiperRef = useRef(null)
    const canLoop = slides && slides.length > 1

    const params = {
        loop: canLoop,
    }

    useEffect(() => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            if (type === 'expertise') {
                const timer = setInterval(() => {
                    nextSlide()
                }, 4000);

                return () => clearInterval(timer);
            }
        }
    }, []);

    const nextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const prevSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    const renderSlides = () => {
        if (!slides) return

        return slides.map((item, i) => {
            return (
                <Slide key={i} type={type}>
                    {item.image && (
                        <SlideImage
                            style={{ height: '100%' }}
                            src={item?.image?.sizes?.medium2}
                        />
                    )}
                </Slide>
            )
        })
    }

    return (
        <Wrapper type={type}>
            <Swiper {...params} ref={swiperRef}>
                {renderSlides()}
            </Swiper>
            {type !== 'expertise' && (
                <>
                    <PrevButton>
                        <Icon onClick={prevSlide} src={sliderArrow} />
                    </PrevButton>
                    <NextButton>
                        <Icon onClick={nextSlide} src={sliderArrow} />
                    </NextButton>
                </>
            )}
        </Wrapper>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const NextButton = styled.div``
const PrevButton = styled.div``
const Icon = styled.img`
    width: 100%;
    height: auto;
`

const Wrapper = styled.div`
    position: relative;
    width: 100%;

    ${props => {
        if (props.type === 'expertise')
            return css`
                &, .swiper-container, .swiper-wrapper {
                    height: 100%;

                    ${media.mob`
                        height: 400px;
                    `}
                }
             `
    }}

    ${NextButton},
    ${PrevButton} {
        ${tw`absolute`};
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        ${Icon} {
            cursor: pointer;
            outline: 0;
            width: 2.375rem;
        }
    }

    ${PrevButton} {
        left: 2rem;
        transform: rotate(180deg);
    }

    ${NextButton} {
        right: 2rem;
    }

    /* custom swiper styles */
    /* .swiper-container {} */
`

const Slide = styled.div`
    ${BGImage} {
        width: 100%;
        height: 0;
        padding-top: 63.846622%;
    }

    ${props => {
        if (props.type === 'expertise')
            return css`
                &, ${BGImage} {
                    height: 100%;
                }
                ${BGImage} {
                    padding-top: 0;
                }
            `
    }}
`

const SlideImage = styled.img`
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export default Slider
