import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, button } from '../../../styles/global'
import { renderLink } from '../../../utils'

const BlockTable = props => {
    const { table } = props
    console.log(table)
    if (!table) return

    const renderHeader = () => {
        if (!table.header) return

        return table.header.map((item, i) => {
            return (
                <th class="border border-blue px-4 py-2">
                    {item.c}
                </th>
            )
        })
    }

    const renderCells = cells => {
        if (!cells) return

        return cells.map((item, i) => {
            return (
                <td class="border border-blue px-4 py-2">
                    {item.c}
                </td>
            )
        })
    }

    const renderRows = () => {
        if (!table.body) return

        return table.body.map((item, i) => {
            return (
                <tr>
                    {renderCells(item)}
                </tr>
            )
        })
    }

    return (
        <Wrapper>
            <table class="table-auto w-full">
                <thead>
                    <tr>
                        {renderHeader()}
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`flex flex-col items-start w-full`};
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

BlockTable.wrapper = css``

export default BlockTable
