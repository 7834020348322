import React from 'react'
import styled, { css } from 'styled-components'
import { GlobalStyles } from './src/styles/global'
import tw from 'tailwind.macro'
import Div100vh from 'react-div-100vh'
import Helmet from 'react-helmet'

import { MobileMenuProvider } from './src/components/MobileMenu/context'

export default ({ element, props }) => {
    return (
        <>
            <MobileMenuProvider>
                <GlobalStyles />
                <Helmet>
                    <link rel="stylesheet" href="https://use.typekit.net/ohg5bcj.css" />
                    <meta name="google-site-verification" content="K2QHIkeYJZFqRrPCAFETIBoCAYvEa_X-wv84Y7LGfTw" />
                </Helmet>
                <Wrapper>
                    {element}
                </Wrapper>
            </MobileMenuProvider>
        </>
    )
}

const Wrapper = styled.div`
    ${tw`relative w-full h-screen`};

    #gatsby-focus-wrapper {
        ${tw`w-full h-full`};
    }
`