import {
    BlockText,
    BlockImage,
    BlockVideo,
    BlockAudio,
    BlockImageSlider,
    BlockTable,
    BlockSignup,
} from './templates'

export const resolveBlock = layout => {
    let block

    // Map layout strings to blocks

    const blocks = {
        text_block: BlockText,
        image_block: BlockImage,
        video_block: BlockVideo,
        audio_block: BlockAudio,
        image_slider_block: BlockImageSlider,
        table_block: BlockTable,
        signup_block: BlockSignup,
    }

    return blocks[layout]
}
