import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useSearchParam, useMount } from 'react-use';

import { Layout, PrivateRoute } from '../components'
import { fetchData } from '../services/api';

import Home from './index'
// import About from './about'
// import ContactUs from './contact-us'
import Services from './services'
import News from '../templates/news-listing'
import CaseStudies from './case-studies'
import servicesSingle from '../templates/services-single'
import casestudiesSingle from '../templates/casestudies-single'
import newsSingle from '../templates/news-single'
import DefaultPage from '../templates/default-page'
import campaignSingle from '../templates/campaign-single'

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
    const postID = useSearchParam('id');
    
	useMount(() => {
		fetchData(`/accumulus/preview/${postID}`, true, true)
			.then(response => {		    	
				let data = response;
				setData(data)
				setPostType(data.post_type)
				console.log('data', data)
				console.log(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			'home': Home,
			'about': DefaultPage,
			'default': DefaultPage,
			'contact': DefaultPage,
			'services-page': Services,
			'casestudies-page': CaseStudies,
			'news-page': News,

			'services': servicesSingle,
			'case_studies': casestudiesSingle,
			'news': newsSingle,
			'campaigns': campaignSingle,
		}

        const Component = templates[postType];

		return (
			<Component
				previewData={data.acf}
				pageContext={{
					data: {
						...data,
						...data.acf
					}
				}}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

