import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { filter } from 'lodash'
import ReactPlayer from 'react-player'

import { parseACF, renderLink } from '../utils'
import { Layout, Features, Grid, Slider, Block, AnnouncementBar } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, button } from '../styles/global'

import { headerHeight } from '../components/Header/Header'
import CardImage from '../components/CardImage';

const Index = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')

    const renderHero = () => {
        return (
            <Hero>
                {data.hero_media_type === 'image' && data.hero_image && (
                    <Image
                        key={data.hero_image.id}
                        src={data.hero_image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
                {data.hero_media_type === 'video' && data.hero_video && (
                    <Video
                        url={data.hero_video}
                        controls={false}
                        playsinline
                        muted
                        playing
                        loop
                        width={'100%'}
                        height={'100%'}
                    />
                )}
                <Overlay />
                {data.hero_text && (
                    <Container>
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: data.hero_text,
                            }}
                        />
                        {data.hero_link && (
                            <Button>
                                {renderLink(data.hero_link, 0)}
                            </Button>
                        )}
                    </Container>
                )}
                <Icon
                    src={require('../assets/images/icons/scroll-arrow.svg')}
                />
            </Hero>
        )
    }

    const renderAbout = () => {
        if (!data.about_items) return
        const items = data.about_items.map((item, i) => {
            return (
                <Item key={i}>
                    {item.image && (
                        <Image
                            key={item.image.id}
                            src={item.image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <CardImage
                                        padding={'76.5%'}
                                        src={src}
                                        styles={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {item.title && (
                        <Heading>{item.title}</Heading>
                    )}
                    {item.description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                    )}
                </Item>
            )
        })

        return (
            <About>
                <Container>
                    {data.about_heading && (
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: data.about_heading,
                            }}
                        />
                    )}

                    <Items>
                        <Grid
                            items={items}
                        />
                    </Items>
                </Container>
            </About>
        )
    }

    const renderServices = () => {
        if (!data.services) return

        const items = data.services.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/services/${item.slug}`}>
                    {item.acf.tile_icon && (
                        <Image
                            key={item.acf.tile_icon.sizes.medium2}
                            src={item.acf.tile_icon.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {item.title && (
                        <Heading>{item.title}</Heading>
                    )}
                    {item.acf.tile_description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.acf.tile_description,
                            }}
                        />
                    )}
                </Item>
            )
        })

        return (
            <Services>
                <Container>
                    <Heading>Business Services</Heading>
                    <Items>
                        <Grid
                            items={items}
                        />
                    </Items>
                    <Button as={Link} to={`/services`}>
                        {data.services_link_text ? data.services_link_text : 'Read More'}
                    </Button>
                </Container>
            </Services>
        )
    }

    const renderExpertiseItems = () => {
        return data.expertise_items.map((item, i) => {
            return (
                <Item key={i}>
                    {item.text}
                </Item>
            )
        })
    }

    const renderExpertise = () => {
        return (
            <Expertise>
                <FlexGrid>
                    <Left>
                        {data.expertise_heading && (
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.expertise_heading,
                                }}
                            />
                        )}
                        {data.expertise_items && (
                            <Items>
                                {renderExpertiseItems()}
                            </Items>
                        )}
                    </Left>
                    <Right>
                        {data.expertise_image && (
                            <Image
                                key={data.expertise_image.id}
                                src={data.expertise_image.sizes.medium2}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage
                                            // image={src} 
                                            src={src}
                                            style={{ opacity: loading ? 0 : 1 }}
                                        />
                                    )
                                }}
                            </Image>
                        )}
                        {data.expertise_images && (
                            <Slider
                                slides={data.expertise_images}
                                type={'expertise'}
                            />
                        )}
                    </Right>
                </FlexGrid>
            </Expertise>
        )
    }

    const renderCaseStudyItems = items => {
        if (!items) return

        return items.map((item, i) => {
            return (
                <Item key={i}>{item.value}</Item>
            )
        })
    }


    const renderCaseStudies = () => {
        if (!data.case_studies) return

        const items = data.case_studies.map((item, i) => {
            return (
                <Item key={i}>
                    <FlexGrid>
                        <Left>
                            <Heading>Case Study {`0${i + 1}`}</Heading>
                            <Items>
                                {item.title && (
                                    <Item>
                                        {item.title}
                                    </Item>
                                )}
                                {renderCaseStudyItems(item.acf.intro_information)}
                            </Items>

                            <Button
                                as={Link}
                                to={`/casestudies/${item.slug}`}
                            >
                                View full case study
                            </Button>
                        </Left>
                        <Right>
                            {item.acf.hero_image && (
                                <ImageWrapper
                                    key={item.acf.hero_image.id}
                                >
                                    <CaseStudyImage
                                        style={{ height: '100%' }}
                                        src={item.acf.hero_image.sizes.medium2}
                                    />
                                </ImageWrapper>
                            )}
                        </Right>
                    </FlexGrid>
                </Item>
            )
        })

        return (
            <CaseStudies>
                {items}
            </CaseStudies>
        )
    }

    const renderCTA = () => {
        return (
            <CTA>
                <Container>
                    {data.cta_icon && (
                        <Image
                            key={data.cta_icon.sizes.medium2}
                            src={data.cta_icon.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {data.cta_heading && (
                        <Heading>{data.cta_heading}</Heading>
                    )}
                    {data.cta_description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: data.cta_description,
                            }}
                        />
                    )}
                    {data.cta_link && (
                        <Button>
                            {renderLink(data.cta_link, 0)}
                        </Button>
                    )}
                </Container>
            </CTA>
        )
    }

    const renderNews = () => {
        if (!data.news) return
        const items = data.news.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/news/${item.slug}`}>
                    {item.acf.tile_icon && (
                        <ProgressiveImage
                            key={item.acf.tile_icon.sizes.medium2}
                            src={item.acf.tile_icon.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <CardImage
                                        padding={'76.5%'}
                                        src={src}
                                        styles={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </ProgressiveImage>
                    )}
                    <Text>
                        {item.title && (
                            <Heading>{item.title}</Heading>
                        )}
                        {item.acf.tile_description && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: item.acf.tile_description,
                                }}
                            />
                        )}
                        <Button>
                            Read more
                        </Button>
                    </Text>
                </Item>
            )
        })
        return (
            <News>
                <Container>
                    <Heading>
                        Current News from Accumulus Advisory
                    </Heading>
                    <Items>
                        <Grid
                            items={items}
                        />
                    </Items>
                </Container>
            </News>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            announcementDisplay={data?.announcement_display}
            announcementData={{
                text: data?.announcement_text,
                includeLink: data?.announcement_include_link,
                link: data?.announcement_link
            }}
        >
            <Wrapper>
                {data.hero_display && renderHero()}
                {renderAbout()}
                {renderServices()}
                {renderExpertise()}
                {renderCaseStudies()}
                {renderCTA()}
                {renderNews()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Button = styled.div``
const ExternalLink = styled.a``
const Items = styled.div``
const Item = styled.div``
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
const Icon = styled.img``
const Video = styled(ReactPlayer)`
    width: 100%!important;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Left = styled.div``
const Right = styled.div``
const FlexGrid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }
`


// Hero

const Overlay = styled.div``
const Hero = styled.div`
    ${tw`relative`};
    height: calc(100vh - ${headerHeight});
    min-height: 500px;

    ${Overlay} {
        ${tw`absolute inset-0 bg-blue`};
        opacity: 0.5;
    }

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
    }

    ${Video} {
        overflow: hidden;
    }

    ${Video}, video {
        ${tw`absolute inset-0 w-full h-full`};
    }

    video {
        object-fit: cover;
    }

    ${Container} {
        ${tw`absolute inset-0 flex flex-col justify-center items-start`};
        z-index: 2;
    }

    ${Heading} {
        ${tw`w-full font-normal text-grey`};
        font-size: 2.5rem;
    }

    ${Button} {
        margin-top: 2rem;

        div, a {
            ${button}
            ${tw`border-grey text-grey`};
            font-size: 1rem;
        
            &:hover {
                ${tw`bg-orange`};
            }
        }
    }

    ${Icon} {
        z-index: 2;
        ${tw`absolute`};
        left: 50%;
        bottom: 2rem;
        width: 2rem;
        height: 2rem;
        transform: translateX(-50%);
    }
`

// About

const About = styled.div`
    padding-top: 8rem;
    padding-bottom: 7rem;

    ${Container} > ${Heading} {
        ${tw`text-center`};
        font-size: 2rem;
    }

    ${Items} {
        margin-top: 4rem;
    }

    ${Item} {
        ${Heading} {
            margin-top: 1.5rem;
            font-size: 1.5rem;
        }

        ${Description} {
            margin-top: 1.5rem;
            font-size: 1rem;
        }
    }
`


// Services

const Services = styled.div`
    ${tw`bg-blue`};
    padding-top: 9rem;
    padding-bottom: 9rem;

    ${Container} {
        ${tw`flex flex-col items-center `};

        > ${Heading} {
            ${tw`text-grey text-center`};
            font-size: 2rem;
        }
    }

    ${Items} {
        margin-top: 3.5rem;
    }

    .grid-item {
        margin-bottom: 0!important;
    }

    ${Item} {
        ${tw`flex flex-col items-center p-12 text-center text-grey`};

        ${LoadedImage} {
            max-height: 6rem;
            max-width: 10rem;
        }

        ${Heading} {
            ${tw`mt-10 font-medium`};
            font-size: 1.5rem;
        }

        ${Description} {
            ${tw`py-5`};
            font-size: 1rem;
        }
    }

    ${Button} {
        margin-top: 3rem;
        ${button}
        ${tw`border-grey text-grey`};
        padding-left: 4rem;
        padding-right: 4rem;
        font-size: 1rem;
        ${tw`bg-blue`};

        ${media.mob`
            padding-left: 2rem;
            padding-right: 2rem;
        `}
        
        &:hover {
            ${tw`bg-grey text-blue`};
        }
    }
`


// Exptertise

const Expertise = styled.div`
    ${Left} {
        max-width: ${1680 / 2}px;
        margin-left: auto;
        ${padding};
        padding-top: 7rem;
        padding-bottom: 8rem;

        ${Heading} {
            font-size: 1.5rem;
        }

        ${Items} {
            ${tw`flex flex-col`};
            margin-top: 4rem;

            ${Item} {
                ${tw`py-2 border-t border-blue`};
                font-size: 1rem;
                line-height: 1;

                &:last-child {
                    ${tw`border-b`};
                }
            }
        }
    }

    ${Right} {
        ${media.mob`
            order: -1;
        `}

        ${BGImage} {
            ${tw`w-full h-full`};

            ${media.mob`
                height: 400px;
            `}
        }
    }

    ${media.mob`
        ${Left}, ${Right} {
            max-width: 100%;
        }
    `}
`


// Case Studies

const CaseStudies = styled.div`
    ${Left} {
        ${tw`flex flex-col items-start`};
        max-width: ${1680 / 2}px;
        margin-left: auto;
        ${padding};
        padding-top: 7rem;
        padding-bottom: 8rem;

        ${Heading} {
            font-size: 1.5rem;
        }

        ${Items} {
            ${tw`flex w-full flex-col`};
            margin-top: 3rem;

            ${Item} {
                ${tw`w-full py-2 border-t border-blue`};
                font-size: 1rem;
                line-height: 1;

                &:last-child {
                    ${tw`border-b`};
                }
            }
        }

        ${Button} {
            ${button}
            margin-top: 3rem;
            font-size: 1rem;
        
            &:hover {
                ${tw`bg-blue text-grey`};
            }
        }
    }

    ${Right} {
        ${media.mob`
            order: -1!important;
        `}
    }

    ${Item} {
        &:nth-child(odd) ${Left} {
            order: 1;
            margin-right: auto;
            margin-left: 0;
        }
    }
`
const ImageWrapper = styled.div`
    position: relative;
    ${tw`w-full h-full`};

     ${media.mob`
        height: 400px;
    `}
`;

const CaseStudyImage = styled.img`
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
`;


// CTA

const CTA = styled.div`
    ${tw`bg-blue`};
    padding-top: 5rem;
    padding-bottom: 6rem;

    ${Container} {
        ${tw`flex flex-col items-center p-12 text-center text-grey`};
        max-width: 65rem;

        @media (max-width: 65rem) {
            max-width: 100%;
        }
    }

    ${LoadedImage} {
        max-height: 6rem;
        max-width: 10rem;
    }

    ${Heading}, ${Description} {
        font-size: 1.5rem;
    }

    ${Heading} {
        ${tw`mt-12 font-medium`};
    }

    ${Description} {
        ${tw`mt-8`};
    }

    ${Button} {
        ${tw`mt-16`};

        div, a {
            ${button}
            ${tw`border-grey text-grey`};
            font-size: 1rem;
            padding-left: 4rem;
            padding-right: 4rem;

            ${media.mob`
                padding-left: 2rem;
                padding-right: 2rem;
            `}
            
            ${tw`bg-blue`};
            
            &:hover {
                ${tw`bg-grey text-blue`};
            }
        }
    }
`


// News

const Text = styled.div``
const News = styled.div`
    padding-top: 8rem;
    padding-bottom: 7rem;

    ${Container} > ${Heading} {
        ${tw`text-center`};
        font-size: 2rem;
    }

    ${Items} {
        margin-top: 4rem;
    }

    ${Item} {
        ${tw`flex flex-col border border-blue`};
        flex-grow: 1;

        ${Text} {
            ${tw`flex flex-col items-center w-full p-8 border-t border-blue`};
            flex-grow: 1;
        }
    }

    ${Heading} {
        ${tw`w-full`};
        font-size: 1.5rem;
    }

    ${Description} {
        margin-top: 1.75rem;
        margin-bottom: 3rem;
    }

    ${Button} {
        margin-top: auto;
        ${button}
        font-size: 1rem;
        
        &:hover {
            ${tw`bg-blue text-grey`};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
