import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Form from 'react-dynamic-form'
import { required, email } from '../../utils/validators'
import tw from 'tailwind.macro'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { media } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'

const SignupForm = props => {
    // console.log(props)
    const signupFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const data = {
            name: fields.name.value,
            email: fields.email.value,
        }

        let nameSplit = data.name.split(' ')
        let firstName
        let lastName

        if (nameSplit.length === 1) {
            firstName = nameSplit[0]
        } else if (nameSplit.length >= 2) {
            firstName = nameSplit[0]
            lastName = nameSplit[1]
        }

        setSubmitting(true)

        addToMailchimp(fields.email.value, {
            FNAME: firstName,
            LNAME: lastName,
        }).then(data => {
            if (data.result == 'success') {
                setFormComplete(true)
                setSubmitting(false)
            }
            
            if (data.result == 'error') {
                setError(data?.msg)
                setSubmitting(false)
            } 
        })
    }

    return (
        <Wrapper
            formComplete={formComplete}
        >
            {!formComplete && (
                <Heading className={`form-heading`}>
                    Sign up to our newsletter
                </Heading>
            )}

            {formComplete && (
                <Heading className={`form-heading`}>
                    Thanks for signing up
                </Heading>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
                submitting={submitting}
            >
                <Form
                    ref={signupFormRef}
                    data={signupFormFields()}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                <Submit>
                    <button
                        className={'submit-button'}
                        disabled={submitting ? true : false}
                        onClick={() => {
                            signupFormRef.current &&
                                signupFormRef.current.handleSubmit()
                        }}
                    >
                        Submit
                    </button>
                </Submit>
            </FormWrapper>

            {error && (
                <Error
                    className={'form-error'}
                    dangerouslySetInnerHTML={{
                        __html: error,
                    }}
                />
            )}
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Error = styled.div``
const Submit = styled.div``

const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start border-b border-t border-blue`};
    padding: 3rem 0 3.5rem;

    ${Heading}, ${Description} {
        ${tw`font-body text-blue`};
        font-size: 2rem;
        line-height: 1;
    }

    ${Error} {
        ${tw`font-body`};
        font-size: 1rem;
        margin-top: 1rem;
        color: rgb(203, 0, 0);
    }
`

const FormWrapper = styled.div`
    ${tw`flex w-full`};
    margin-top: 2rem;

    ${media.mob`
        ${tw`flex-wrap`};
    `}

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    ${props => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    > div {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .dynamic-fields {}

    .dynamic-field {
        ${tw`w-1/2`};

        &:first-child {
            padding-right: 1rem;
        }

        &:last-child {
            padding-left: 1rem;
        }

        &, * {
            font-size: 1rem;
            line-height: 1;
        }

        /* error message */
        > *:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 1rem;
        }

        /* &:not(:last-child) {
            margin-bottom: 1.5rem;
        } */

        input {
            padding: 1rem 0 1rem;
        }
    }

    input {
        ${tw`border-b border-blue`};
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            ${tw`text-blue`};
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    ${Submit} {
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        padding-left: 1rem;

        ${media.mob`
            margin-top: 2rem;
            padding-left: 0;
        `}

        button {
            appearance: none;
            box-shadow: none;
            background-color: transparent;
            ${button}
            min-width: initial;
            padding-left: 4rem;
            padding-right: 4rem;
            font-size: 1rem;
            line-height: 1;
            ${tw`border-blue text-blue`};

            &:hover {
                ${tw`bg-blue text-grey`};
            }

            &:focus {
                outline: none;
            }
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    render() {
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }
}

export const signupFormFields = (selectOptions) => {
    return {
        fields: [
            {
                type: 'text',
                name: 'name',
                validator: required,
                errorMessage: 'This field is required',
                placeholder: 'First Name Last Name*',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
            {
                type: 'email',
                name: 'email',
                validator: email,
                errorMessage: 'Please enter a valid email address',
                placeholder: 'Email*',
                component: ({ value, placeholder, onChangeValue, validator }) => {
                    return (
                        <Input
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                        />
                    )
                },
            },
        ],
    }
}

export default SignupForm
