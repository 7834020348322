import React, { useState } from 'react'
import { Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import styled, { css, createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'
import tw from 'tailwind.macro'
import { getGlobalData } from '../../utils'

import { ContactForm } from '../../components'
import { type, padding, container, hoverState } from '../../styles/global'
import { media } from '../../styles/utils'
import { renderLink } from '../../utils'

import facebook from '../../assets/images/icons/facebook.svg'
import linkedin from '../../assets/images/icons/linkedin.svg'
import twitter from '../../assets/images/icons/twitter.svg'

const Footer = props => {
    const { global: data } = getGlobalData()
    // console.log(data)

    const renderAwards = () => {
        if (!data.footer_awards) return

        const items = data.footer_awards.map((item, i) => {
            return (
                <Item key={i}>
                    <Image
                        key={item.image.id}
                        src={item.image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage 
                                    src={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image>
                    {item.description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                    )}
                </Item>
            )
        })

        return (
            <Awards>
                {items}
            </Awards>
        )
    }

    const renderContactInfo = () => {
        if (!data.contact_phone && !data.contact_email) return

        return (
            <Info>
                {data.contact_phone && (
                    <Item>
                        <span className={'label'}>
                            Call
                        </span>
                        <a href={`tel:${data.contact_phone}`}>
                            {data.contact_phone}
                        </a>
                    </Item>
                )}
                {data.contact_email && (
                    <Item>
                        <span className={'label'}>
                            Email
                        </span>
                        <a href={`mailto:${data.contact_email}`}>
                            {data.contact_email}
                        </a>
                    </Item>
                )}
            </Info>
        )
    }

    const renderSocialLinks = () => {
        return (
            <SocialLinks>
                {data.facebook_link && (
                    <Item
                        as={ExternalLink}
                        href={data.facebook_link}
                        target={'_blank'}
                    >
                        <Icon src={facebook} /> 
                    </Item>
                )}
                {data.linkedin_link && (
                    <Item
                        as={ExternalLink}
                        href={data.linkedin_link}
                        target={'_blank'}
                    >
                        <Icon src={linkedin} /> 
                    </Item>
                )}
                {data.twitter_link && (
                    <Item
                        as={ExternalLink}
                        href={data.twitter_link}
                        target={'_blank'}
                    >
                        <Icon src={twitter} /> 
                    </Item>
                )}
            </SocialLinks>
        )
    }

    return (
        <Wrapper>
            <Container>
                {renderAwards()}
                <Grid>
                    <Left>
                        {data.contact_address && (
                            <Info
                                dangerouslySetInnerHTML={{
                                    __html: data.contact_address,
                                }}
                            />
                        )}
                        {data.footer_small_text && (
                            <SmallText
                                dangerouslySetInnerHTML={{
                                    __html: data.footer_small_text,
                                }}
                            />
                        )}
                        {renderSocialLinks()}
                    </Left>
                    <Right>
                        {renderContactInfo()}
                        <FormWrapper>
                            <ContactForm
                                heading={`Contact us via the form below.`}
                                cta={`We'll be in contact with you within 4 business hours.`}
                                displayInterest={true}
                            />
                        </FormWrapper>
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Item = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`
const Icon = styled.img``


// Layout

const Wrapper = styled.div`
    ${tw`bg-blue`};
    padding: 9rem 0;

    ${media.mob`
        padding: 7rem 0;
    `}
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Awards

const Awards = styled.div`
    ${tw`flex flex-wrap justify-center -mx-4 -mb-8`};

    ${Item} {
        ${tw`flex flex-col items-center text-center w-1/6 px-4 mb-8`};

        ${media.mob`
            ${tw`w-1/2`};
        `}
    }

    ${Description} {
        ${tw`mt-3`};
        font-size: 0.875rem;
        line-height: 1.4;
        color: #EAE8E5;
    }
`

// Grid

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex -mx-8`};
    margin-top: 7rem;

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`flex flex-col w-1/2 px-8`}

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            order: -1;
            margin-bottom: 7rem;
        `}
    }
`

// Contact Info (address, phone, email)

const Info = styled.div`
    &, * {
        font-size: 1.5rem;
        line-height: 1.33;
        color: #EAE8E5;
    }

    p:not(:last-child) {
        margin-bottom: 1em;
    }

    ${Item} {
        ${tw`flex w-full`};

        ${media.tabPor`
            ${tw`flex-wrap`};

            &:not(:last-child) {
                ${tw`mb-4`};
            }
        `}

        span {
            min-width: 6rem;

            ${media.tabPor`
                min-width: 100%;
            `}
        }

        a {
            flex: 1;
        }
    }
`

// Small Text

const SmallText = styled.div`
    margin-top: auto;
    font-size: 1rem;
    ${tw`text-grey`};

    ${media.mob`
        margin-top: 7rem;
    `}

    p:not(:last-child) {
        margin-bottom: 1em;
    }
`

// Social Links

const SocialLinks = styled.div`
    ${tw`flex`};
    margin-top: 2rem;

    ${Item} {
        ${tw`flex mx-4`};
        width: 3rem;
        height: 3rem;
        ${hoverState}

        &:first-child {
            ${tw`ml-0`};
        }

        &:last-child {
            ${tw`mr-0`};
        }
    }
`

// Contact Form

const FormWrapper = styled.div`
    width: 100%;
    margin-top: 2rem;

    .dynamic-fields {
        margin-top: 3rem;
    }

    .submit-button:hover {
        ${tw`bg-grey text-blue`};
    }
`

export default Footer
