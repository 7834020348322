import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { Slider } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockImageSlider = props => {
    const { images } = props
    if (!images) return

    return (
        <Wrapper>
            <Slider
                slides={images}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

BlockImageSlider.wrapper = css``

export default BlockImageSlider
