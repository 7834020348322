import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { parseACF } from '../utils'
import { Layout, PageHeader, Grid } from '../components'
import { media } from '../styles/utils'
import { container, padding, type, button } from '../styles/global'
import CardImage from '../components/CardImage';

const CaseStudies = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')

    const renderCaseStudyItems = items => {
        if (!items) return

        return items.map((item, i) => {
            return (
                <Subheading key={i}>{item.value}</Subheading>
            )
        })
    }

    const renderCaseStudies = () => {
        if (!data.case_studies) return

        const items = data.case_studies.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/casestudies/${item.slug}`}>
                    {item.acf.hero_image && (
                        <ProgressiveImage
                            key={item.acf.hero_image.sizes.medium2}
                            src={item.acf.hero_image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <CardImage
                                        padding={'76.5%'}
                                        src={src}
                                        styles={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </ProgressiveImage>
                    )}
                    <Text>
                        {item.title && (
                            <Heading>{item.title}</Heading>
                        )}
                        <Info>
                            {renderCaseStudyItems(item.acf.intro_information)}
                        </Info>
                        <Button>
                            Find out more
                        </Button>
                    </Text>
                </Item>
            )
        })

        return (
            <Items>
                <Grid
                    items={items}
                />
            </Items>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={'Case Studies'} subheading={data?.intro_subheading} text={data?.intro_text} />
                    {renderCaseStudies()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``



const Button = styled.div`
    ${button}
`

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`


// Items

const Text = styled.div``
const Item = styled.div``
const Items = styled.div`
    margin-bottom: 9rem;

    ${media.mob`
        margin-bottom: 6rem;
    `}

    ${Item} {
        ${tw`flex flex-col border border-blue`};
        flex-grow: 1;

        ${Text} {
            ${tw`flex flex-col items-center w-full p-8 border-t border-blue`};
            flex-grow: 1;
        }
    }

    ${Heading} {
        ${tw`w-full`};
        font-size: 1.5rem;
    }

    ${Info} {
        ${tw`w-full`};
        margin-top: 1.75rem;
        margin-bottom: 3rem;
    }

    ${Subheading} {
        ${tw`w-full border-t border-blue`};
        padding: 0.5rem 0;
        font-size: 1rem;

        &:last-child {
            margin-bottom: 2rem;
            ${tw`border-b`};
        }
    }

    ${Button} {
        margin-top: auto;
        ${button}
        font-size: 1rem;
        
        &:hover {
            ${tw`bg-blue text-grey`};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "case-studies" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default CaseStudies
