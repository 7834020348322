import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, button } from '../../../styles/global'
import { renderLink } from '../../../utils'

const BlockText = props => {
    const { text, include_button, button_link } = props
    console.log(props)
    if (!text && !button_link) return

    return (
        <Wrapper>
            <Text
                className={'tiny-mce'}
                dangerouslySetInnerHTML={{
                    __html: `${text}`,
                }}
            />
            {include_button && button_link && (
                <Button>
                    {renderLink(button_link)}
                </Button>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`flex flex-col items-start w-full`};
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

const Button = styled.div`
    margin-top: 2rem;
    ${button}

    &:hover {
        ${tw`bg-blue text-grey`};
    }
`

BlockText.wrapper = css``

export default BlockText
