import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { Layout, NewsBlock } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button } from '../styles/global'

const NewsSingle = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext.acf
    const title = pageContext?.title || pageContext?.data?.title
    // console.log(data)
    // console.log(pageContext)

	const renderBlocks = () => {
        if (!data.news_content_blocks) return;

		return data.news_content_blocks.map((block, i) => {
			return (
				<NewsBlock
                    key={i}
                    layout={block?.acf_fc_layout}
                    lastBlock={i+1 === data.news_content_blocks.length}
					{...block}
                />
			)
		})
    }

    const renderMeta = () => {
        return (
            <Meta>
                {pageContext.topic && (
                    <Topic>
                        {pageContext.topic.name}
                    </Topic>
                )}
                {pageContext.date && (
                    <Date>
                        {pageContext.date}
                    </Date>
                )}
            </Meta>
        )
    }

    const renderRelatedArticles = () => {
        const items = data.similar_posts.map((item, i) => {
            return (
                <Item key={i}>
                    <Title>{item.title}</Title>
                    <Date>{item.publishedDate}</Date>
                    <ReadMore as={Link} to={`/news/${item.slug}`}>Read more...</ReadMore>
                </Item>
            )
        })

        return (
            <RelatedArticles>
                {items}
            </RelatedArticles>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Container>
                    <PageHeader>
                        <Grid>
                            <Content>
                                <Heading>{title}</Heading>
                                {pageContext?.template === 'news' && renderMeta()}
                            </Content>
                            <Sidebar />
                        </Grid>
                    </PageHeader>
                    <Main>
                        <Grid>
                            <Content>
                                {renderBlocks()}
                            </Content>
                            <Sidebar>
                                {data.similar_posts && data.similar_posts.length > 0 && (
                                    <>
                                        <Heading>Related articles</Heading>
                                        {renderRelatedArticles()}
                                    </>
                                )}
                            </Sidebar>
                        </Grid>
                    </Main>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div`
    font-size: 2rem;
    line-height: 1.25;
`

const Title = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 5rem;
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Content = styled.div``
const Sidebar = styled.div``

const Grid = styled.div`
    ${tw`flex -mx-4`};

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    > ${Content} {
        ${tw`w-2/3 px-4`};
    }

    > ${Sidebar} {
        ${tw`w-1/3 px-4`};
    }

    ${media.mob`
        > ${Content}, ${Sidebar} {
            ${tw`w-full px-0`};
        }
    `}
`


// Page Header

const PageHeader = styled.div`
    ${tw`w-full`};
    padding-top: 9rem;
    padding-bottom: 6rem;

    ${media.mob`
        padding-top: 6rem;
    `}
`

// Meta 

const Topic = styled.div``
const Date = styled.div``
const Meta = styled.div`
    ${tw`flex items-center`};
    margin-top: 2rem;

    ${Topic} {
        ${button};
        min-width: initial;
        cursor: initial;
        margin-right: 2rem;
    }

    ${Date} {}
`


// Main

const Main = styled.div`

`


// Related Articles

const Item = styled.div``
const ReadMore = styled.div``

const RelatedArticles = styled.div`
    margin-top: 1rem;

    ${Item} {
        ${tw`border-t border-blue`};
        padding: 2rem 0;

        &:last-child {
            ${tw`border-b`};
        }
    }
    
    ${ReadMore} {
        ${tw`inline-block`};
        margin-top: 1rem;
    }
`

export default NewsSingle
