import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'

const PageHeader = props => {
    const { title, subheading, text, isCaseStudy, info } = props

    const renderInfo = () => {
        if (!info) return

        return info.map((item, i) => {
            return (
                <Item key={i}>
                    <Subheading>{item.label}:</Subheading>
                    <Subheading>{item.value}</Subheading>
                </Item>
            )
        })
    }

    return (
        <Wrapper
            className={'page-header'}
        >
            <Grid>
                <Left>
                    {isCaseStudy && (
                        <Heading>Case Study</Heading>
                    )}
                    {!isCaseStudy && title && (
                        <Heading>{title}</Heading>
                    )}
                </Left>
                <Right>
                    {isCaseStudy && (
                        <Items>
                            {title && (
                                <Item>
                                    <Subheading>
                                        {title}
                                    </Subheading>
                                </Item>
                            )}
                            {renderInfo()}
                        </Items>
                    )}
                    {!isCaseStudy && (
                        <>
                            {subheading && (
                                <Subheading>{subheading}</Subheading>
                            )}
                            {text && (
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: text,
                                    }}
                                />
                            )}
                        </>
                    )}
                </Right>
            </Grid>
        </Wrapper>
    )
}

const Heading = styled.h1``
const Subheading = styled.div``
const Description = styled.div``
const Items = styled.div``
const Item = styled.div``

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            ${tw`mt-8`};
        `}
    }
`


const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: 9rem;
    padding-bottom: 6rem;

    ${media.mob`
        padding-top: 6rem;
    `}

    ${Heading}, ${Subheading}, ${Description} {
        ${tw`font-normal text-blue`};
    }

    ${Heading} {
        font-size: 3rem;
        line-height: 1;
    }

    ${Subheading} {
        font-size: 1.5rem;
        line-height: 1.25;
    }

    ${Right} > ${Subheading} {
        margin-top: 0.5rem;
    }

    ${Description} {
        margin-top: 2rem;
        font-size: 1rem;
        line-height: 1.4;

        p:not(:first-child) {
            margin-top: 1em;
        }
    }

    ${Items} {
        ${tw`flex flex-col items-start`};

        ${Item} {
            ${tw`w-full border-b border-blue`};
            padding-bottom: 1rem;

            &:first-child {
                margin-top: 0.5rem;
            }

            &:not(:first-child) {
                padding-top: 1rem;
            }
        }
    }
`

export default PageHeader
