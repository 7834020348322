import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { parseACF } from '../utils'
import { Layout, PageHeader } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'

import DefaultPage from '../templates/default-page'

const ContactUs = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    // console.log(data)

    return (
        <DefaultPage
            pageContext={{
                title: 'Contact',
                slug: 'contact-us',
                acf: data
            }}
        />
    )
}

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact-us" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default ContactUs
