import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_KEY } from '../../constants'
import { mapTheme } from '../../styles/map'
import { useLocation } from 'react-use'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'

import mapMarker from '../../assets/images/logos/accumulus-advisory-logo-dark.svg'

const GoogleMap = props => {
    const location = useLocation()
    const { map, padding } = props
    if (!location || !map) return

    const mapProps = {
        defaultZoom: 18,
        defaultCenter: {
            lat: parseFloat(map.lat),
            lng: parseFloat(map.lng),
        },
        bootstrapURLKeys: {
            key: GOOGLE_MAPS_KEY,
        },
        options: {
            styles: mapTheme,
            // gestureHandling: 'none',
            // disableDefaultUI: true,
        },
    }

    const markerProps = {
        lat: parseFloat(map.lat),
        lng: parseFloat(map.lng),
    }

    return (
        <MapIframe>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12619.518526336527!2d144.909997!3d-37.745968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24922ec382e76eaf!2sAccumulus%20Advisory!5e0!3m2!1sen!2sua!4v1624461793072!5m2!1sen!2sua"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy" />
        </MapIframe>
        // <Map id={'google-map'}>
        //     <GoogleMapReact {...mapProps}>
        //         <Marker
        //             {...markerProps}
        //         >
        //             <Icon src={mapMarker} />
        //         </Marker>
        //     </GoogleMapReact>
        // </Map>
    )
}

const MapIframe = styled.div`
    position: relative;

    &:before {
        width: 100%;
        display: block;
        content: '';
        padding-bottom: 76.1644%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const Map = styled.div`
    ${tw`absolute inset-0 w-full h-full`};

    &,
    * {
        background-color: unset!important;
        cursor: initial!important;
    }

    button {
        background: none rgb(255, 255, 255)!important;
        cursor: pointer!important;
    }
`

const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: auto;
    padding: 2px;
    transform: translate(-50%, -100%);
    background-color: white!important;
    border-radius: 50%;
    box-shadow: 2px 2px 5px rgba(12, 28, 32, 0.5);
`

const Icon = styled.img``

GoogleMap.propTypes = {
    map: PropTypes.object,
}

export default GoogleMap
