import React, { useState, useContext, Component, useRef, useEffect } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useLocation, useMount, useUnmount, useSetState } from 'react-use'
import Form from 'react-dynamic-form'
import { required, email, phone } from '../../utils/validators'
import tw from 'tailwind.macro'
import { postData } from '../../services/api'

import { media } from '../../styles/utils'
import { container, padding, button, type } from '../../styles/global'

import arrow from '../../assets/images/icons/select-arrow.svg'
import darkArrow from '../../assets/images/icons/select-arrow-dark.svg'

const ContactForm = props => {
    const { heading, cta, theme, displayInterest } = props
    const contactFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)
    const selectOptions = useStaticQuery(query)?.allWordpressServices?.nodes

    useEffect(() => {
        if (formComplete) {
            navigate('/thank-you')
        }
    }, [formComplete])

    const handleSubmit = (fields, resetForm) => {
        const data = {
            name: fields.name.value,
            email: fields.email.value,
            phone: fields.phone.value,
            interest: fields?.interest?.value || 'not provided',
            notes: fields.notes.value,
        }

        // console.log(data)

        setSubmitting(true)

        postData('/accumulus/contact', data)
            .then(res => {
                console.log(res)
                if (res.success) {
                    setFormComplete(true)
                    setSubmitting(false)
                } else {
                    setError(res.errors[0])
                    setSubmitting(false)
                }

                resetForm()
            })
            .catch(error => {
                setError(error)
                setSubmitting(false)
                resetForm()
            })
    }

    return (
        <Wrapper
            formComplete={formComplete}
            theme={theme}
        >
            {heading && (
                <Heading className={`form-heading`}>
                    {heading}
                </Heading>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
                submitting={submitting}
                theme={theme}
            >
                <Form
                    ref={contactFormRef}
                    data={contactFormFields(selectOptions, theme, displayInterest)}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                {error && (
                    <Error
                        className={'form-error'}
                        dangerouslySetInnerHTML={{
                            __html: error,
                        }}
                    />
                )}

                <Submit>
                    <button
                        className={'submit-button'}
                        disabled={submitting ? true : false}
                        onClick={() => {
                            contactFormRef.current &&
                                contactFormRef.current.handleSubmit()
                        }}
                    >
                        Submit
                    </button>
                    {cta && (
                        <Info className={'form-cta'}>{cta}</Info>
                    )}
                </Submit>
            </FormWrapper>
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Error = styled.div``
const Submit = styled.div``


const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start`};

    ${Heading}, ${Description} {
        ${tw`font-body text-grey`};
        font-size: 1.5rem;
        line-height: 1.33;
    }

    ${props => {
        if (props.theme === 'dark')
            return css`
                ${Heading}, ${Description} {
                    ${tw`text-blue`};
                }
            `
    }}

    ${Heading} {
        ${props => {
            if (props.formComplete)
                return css`
                    opacity: 0;
                `
        }}
    }

    ${Error} {
        ${tw`font-body`};
        font-size: 1rem;
        margin-top: 1rem;
        color: rgb(203, 0, 0);
    }
`

const FormWrapper = styled.div`
    ${tw`flex flex-col items-start`};

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    ${props => {
        if (props.submitting)
            return css`
                opacity: 0.5;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    &,
    > div {
        width: 100%;
    }

    .dynamic-field {
        &, * {
            font-size: 1rem;
            line-height: 1;
        }

        /* error message */
        > *:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 1rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        input, select, textarea {
            padding: 1rem 0 1rem;
        }
    }

    input, textarea, select {
        ${tw`border-b border-grey`};
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        ${props => {
            if (props.theme === 'dark')
                return css`
                    ${tw`border-blue`};
                `
        }}

        &,
        &::placeholder {
            ${tw`text-grey`};
            opacity: 1;

            ${props => {
                if (props.theme === 'dark')
                    return css`
                        ${tw`text-blue`};
                    `
            }}
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    textarea {
        line-height: 1.25!important;
    }

    .select-wrapper {
        position: relative;
        width: 100%;

        select {
            position: relative;
            z-index: 1;
            background-color: transparent;
            line-height: 1.25;
        }

        img {
            position: absolute;
            right: 0;
            top: 50%;
            width: auto;
            height: 12px;
            margin-top: -6px;
        }
    }

    textarea {
        resize: none;
    }

    ${Submit} {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 2.5rem;

        button {
            margin-right: 2rem;
            appearance: none;
            box-shadow: none;
            background-color: transparent;
            ${button}
            font-size: 1rem;
            line-height: 1;
            ${tw`border-grey text-grey`};

            ${props => {
                if (props.theme === 'dark')
                    return css`
                        ${tw`border-blue text-blue`};
                    `
            }}

            &:focus {
                outline: none;
            }
        }

        ${Info} {
            ${tw`font-body`};
            font-size: 1rem;
            line-height: 1.33;
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderInput = () => {
        const { type } = this.props
        if (type === 'textarea') {
            return (
                <textarea rows={'6'} onChange={this.onChange} placeholder={this?.props?.placeholder} />    
            )
        }
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }

    render() {
        return this.renderInput()
    }
}

class Select extends Component {
    onChange = e => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderOptions = options => {
        return options.map((item, i) => {
            return (
                <option key={i} value={item.slug}>
                    {item.title}
                </option>
            )
        })
    }

    render() {
        const { placeholder, options, theme } = this.props
        return (
            <div className={'select-wrapper'}>
                <select onChange={this.onChange}>
                    <option value="" disabled selected>{placeholder}</option>
                    {this.renderOptions(options)}
                </select>
                <img src={theme === 'dark' ? darkArrow : arrow}/>
            </div>
        )
    }
}

export const contactFormFields = (selectOptions, theme, displayInterest) => {
    if (!displayInterest) {
        return {
            fields: [
                {
                    type: 'text',
                    name: 'name',
                    validator: required,
                    errorMessage: 'This field is required',
                    placeholder: 'First Name Last Name*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'email',
                    name: 'email',
                    validator: email,
                    errorMessage: 'Please enter a valid email address',
                    placeholder: 'Email*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'text',
                    name: 'phone',
                    validator: phone,
                    errorMessage: 'Please enter a valid phone number',
                    placeholder: 'Phone Number*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'textarea',
                    name: 'notes',
                    placeholder: 'Notes',
                    component: ({ value, placeholder, onChangeValue, type }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                type={type}
                            />
                        )
                    },
                },
            ],
        }
    } else {
        return {
            fields: [
                {
                    type: 'text',
                    name: 'name',
                    validator: required,
                    errorMessage: 'This field is required',
                    placeholder: 'First Name Last Name*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'email',
                    name: 'email',
                    validator: email,
                    errorMessage: 'Please enter a valid email address',
                    placeholder: 'Email*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'text',
                    name: 'phone',
                    validator: phone,
                    errorMessage: 'Please enter a valid phone number',
                    placeholder: 'Phone Number*',
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                            />
                        )
                    },
                },
                {
                    type: 'select',
                    name: 'interest',
                    placeholder: 'Area of Interest*',
                    errorMessage: 'This field is required',
                    validator: required,
                    component: ({ value, placeholder, onChangeValue, validator }) => {
                        return (
                            <Select
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                validator={validator}
                                options={selectOptions}
                                theme={theme}
                            />
                        )
                    },
                },
                {
                    type: 'textarea',
                    name: 'notes',
                    placeholder: 'Notes',
                    component: ({ value, placeholder, onChangeValue, type }) => {
                        return (
                            <Input
                                value={value}
                                placeholder={placeholder}
                                onChangeValue={onChangeValue}
                                type={type}
                            />
                        )
                    },
                },
            ],
        }
    }
}

export const query = graphql`
    query {
        allWordpressServices {
            nodes {
                slug
                title
            }
        }
    }
`

export default ContactForm
