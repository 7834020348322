import { BlockTextMedia, BlockDownload, BlockCaseStudy, BlockContactForm, BlockGoogleMap } from './templates'

export const resolveBlock = layout => {
    let block

    // Map layout strings to blocks

    const blocks = {
        text_image_block: BlockTextMedia,
        download_block: BlockDownload,
        case_study_block: BlockCaseStudy,
        contact_form_block: BlockContactForm,
        google_maps_block: BlockGoogleMap,
    }

    return blocks[layout]
}
