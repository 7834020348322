import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { ContactForm } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockContactForm = props => {
    const { heading, content, lastBlock, lastTextBlock } = props
    // console.log(props)

    return (
        <Wrapper lastBlock={lastBlock} lastTextBlock={lastTextBlock}>
            <Grid>
                <Left>
                    {heading && <Heading>{heading}</Heading>}
                </Left>
                <Right>
                    {content && (
                        <Text
                            className={'tiny-mce'}
                            dangerouslySetInnerHTML={{
                                __html: `${content}`,
                            }}
                        />
                    )}
                    <FormWrapper>
                        <ContactForm
                            theme={'dark'}
                            displayInterest={true}
                        />
                    </FormWrapper>
                </Right>
            </Grid>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full border-b border-blue`};
    padding-bottom: 4rem;

    ${props => {
        if (props.lastBlock || props.lastTextBlock)
            return css`
                padding-bottom: 0;
                border-bottom: none;
            `
    }}
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.mob`
        ${tw`block mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            margin-top: 3rem;
        `}
    }
`

// Text

const Heading = styled.div`
    font-size: 1.5rem;
    line-height: 1.25;
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

// Contact Form

const FormWrapper = styled.div`
    width: 100%;
    margin-top: 2rem;

    .submit-button:hover {
        ${tw`bg-blue text-grey`};
    }
`

BlockContactForm.wrapper = css``

export default BlockContactForm
