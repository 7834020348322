import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockImage = props => {
    const { image } = props
    if (!image) return

    return (
        <Wrapper>
            <Image
                key={image?.sizes?.large}
                src={image?.sizes?.large}
            >
                {(src, loading) => {
                    return (
                        <LoadedImage 
                            src={src} 
                            style={{opacity: loading ? 0 : 1}}  
                        />
                    )
                }}
            </Image> 
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

BlockImage.wrapper = css``

export default BlockImage
