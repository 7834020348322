// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-campaign-single-js": () => import("./../../../src/templates/campaign-single.js" /* webpackChunkName: "component---src-templates-campaign-single-js" */),
  "component---src-templates-casestudies-single-js": () => import("./../../../src/templates/casestudies-single.js" /* webpackChunkName: "component---src-templates-casestudies-single-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-services-single-js": () => import("./../../../src/templates/services-single.js" /* webpackChunkName: "component---src-templates-services-single-js" */)
}

