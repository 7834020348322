import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { getGlobalData } from '../utils'
import ReactPlayer from 'react-player'

import { renderLink } from '../utils'
import { Layout, NewsBlock, ContactForm } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button, type } from '../styles/global'
import { headerHeight } from '../components/Header/Header'

const CampaignSingle = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext.acf
    const title = pageContext?.title || pageContext?.data?.title
    const { global: global_data } = getGlobalData()
    console.log(global_data)
    // console.log(pageContext)

    const renderHero = () => {
        return (
            <Hero>
                {data.hero_media_type === 'image' && data.hero_image && (
                    <Image
                        key={data.hero_image.id}
                        src={data.hero_image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage 
                                    image={src} 
                                    style={{opacity: loading ? 0 : 1}}  
                                />
                            )
                        }}
                    </Image>
                )}
                {data.hero_media_type === 'video' && data.hero_video && (
                    <Video
                        url={data.hero_video}
                        controls={false}
                        playsinline
                        muted
                        playing
                        loop
                        width={'100%'}
                        height={'100%'}
                    />
                )}
                <Overlay />
                {data.hero_text && (
                    <Container>
                        <Grid>
                            <Content>
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: data.hero_text,
                                    }}
                                />
                                {data.hero_link && (
                                    <Button>
                                        {renderLink(data.hero_link, 0)}
                                    </Button>
                                )}
                            </Content>
                            <Sidebar>
                                <ContactForm
                                    theme={'dark'}
                                    displayInterest={false}
                                />
                            </Sidebar>
                        </Grid>
                    </Container>
                )}
                <Icon
                    src={require('../assets/images/icons/scroll-arrow.svg')}
                />
            </Hero>
        )
    }

    const renderContactInfo = () => {
        if (!global_data.contact_phone && !global_data.contact_email) return

        return (
            <Info>
                {global_data.contact_phone && (
                    <Item>
                        <span className={'label'}>
                            Call:&nbsp;
                        </span>
                        <a href={`tel:${global_data.contact_phone}`}>
                            {global_data.contact_phone}
                        </a>
                    </Item>
                )}
                {global_data.contact_email && (
                    <Item>
                        <span className={'label'}>
                            Email:&nbsp;
                        </span>
                        <a href={`mailto:${global_data.contact_email}`}>
                            {global_data.contact_email}
                        </a>
                    </Item>
                )}
            </Info>
        )
    }

	const renderBlocks = () => {
        if (!data.news_content_blocks) return;

		return data.news_content_blocks.map((block, i) => {
			return (
				<NewsBlock
                    key={i}
                    layout={block?.acf_fc_layout}
                    lastBlock={i+1 === data.news_content_blocks.length}
					{...block}
                />
			)
		})
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper
                displayHero={data.hero_display}
            >
                {data.hero_display && renderHero()}
                <Container>
                    <Main
                        displayHero={data.hero_display}
                    >
                        <Grid>
                            <Content>
                                <Heading>{title}</Heading>
                                {renderBlocks()}
                            </Content>
                            <Sidebar>
                                {renderContactInfo()}
                                <FormWrapper>
                                    <ContactForm
                                        heading={'Contact us via the form below.'}
                                        displayInterest={false}
                                    />
                                </FormWrapper>
                            </Sidebar>
                        </Grid>
                    </Main>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Button = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`
const Icon = styled.img``
const Video = styled(ReactPlayer)`
    width: 100%!important;
`

const Info = styled.div``

const Item = styled.div``

const Heading = styled.div`
    font-size: 2rem;
    line-height: 1.25;
`

const Title = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: 9rem;
    padding-bottom: 5rem;

    ${media.mob`
        padding-top: 6rem;
    `}

    ${props => {
        if (props.displayHero)
            return css`
                padding-top: 0!important;
            `
    }}
`

const Container = styled.div`
    ${container};
    ${padding};
`

const Content = styled.div``
const Sidebar = styled.div`
    ${Info} {
        ${tw`bg-blue text-white`};
        padding: 2rem 2rem 1.5rem;

        ${Item} {
            line-height: 1.4;
        }
    }
`

// Grid

const Grid = styled.div`
    ${tw`flex -mx-4`};

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}
`


// Hero

const Overlay = styled.div``
const Hero = styled.div`
    ${tw`relative`};
    height: calc(100vh - ${headerHeight});
    min-height: 500px;

    ${Overlay} {
        ${tw`absolute inset-0 bg-blue`};
        opacity: 0.5;
    }

    ${Grid} {
        ${tw`items-center`};

        > ${Content} {
            ${tw`flex flex-col items-start w-2/3 px-4`};
        }

        > ${Sidebar} {
            ${tw`w-1/3 px-4`};

            .form-wrapper {
                ${tw`bg-grey`};
                padding: 1rem 2rem 2rem;

                ${media.mob`
                    display: none;
                `}
            }
        }

        ${media.mob`
            > ${Content}, ${Sidebar} {
                ${tw`w-full px-0`};
            }
        `}
    }

    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
    }

    ${Video} {
        overflow: hidden;
    }

    ${Video}, video {
        ${tw`absolute inset-0 w-full h-full`};
    }

    video {
        object-fit: cover;
    }

    ${Container} {
        ${tw`absolute inset-0 flex flex-col justify-center items-start`};
        z-index: 2;
    }

    ${Heading} {
        ${tw`w-full font-normal text-grey`};
        font-size: 2.5rem;
    }

    ${Button} {
        margin-top: 2rem;

        div, a {
            ${button}
            ${tw`border-grey text-grey`};
            font-size: 1rem;
        
            &:hover {
                ${tw`bg-orange`};
            }
        }
    }

    ${Icon} {
        z-index: 2;
        ${tw`absolute`};
        left: 50%;
        bottom: 2rem;
        width: 2rem;
        height: 2rem;
        transform: translateX(-50%);
    }
`

// Main

const Main = styled.div`
    ${props => {
        if (props.displayHero)
            return css`
                padding-top: 5rem;
            `
    }}

    ${Grid} {
        > ${Content} {
            ${tw`flex flex-col items-start w-2/3 px-4`};
        }

        > ${Sidebar} {
            ${tw`w-1/3 px-4`};
        }

        ${media.mob`
            > ${Content}, ${Sidebar} {
                ${tw`w-full px-0`};
            }
        `}
    }

    ${Content} > ${Heading} {
        padding-bottom: 6rem;

        ${props => {
            if (props.displayHero)
                return css`
                    display: none;
                `
        }}
    }
`

// Form

const FormWrapper = styled.div`
    width: 100%;

    .form-heading, .form-wrapper {
        ${tw`bg-blue`};
    }

    .form-wrapper {
        padding: 0 2rem 2rem;
    }

    .form-heading {
        ${tw`w-full`};
        ${type.body};
        padding: 0 2rem;
    }

    .dynamic-fields {
        margin-top: 2rem;
    }

    .submit-button:hover {
        ${tw`bg-grey text-blue`};
    }
`

export default CampaignSingle
