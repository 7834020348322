import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { find } from 'lodash'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, bgImage, button } from '../../../styles/global'

const BlockCaseStudy = props => {
    const { case_study, caseStudies } = props
    const caseStudyData = find(caseStudies, function(o) { return o.wordpress_id === case_study })
    if (!caseStudyData) return

    const data = {
        title: caseStudyData?.title,
        slug: caseStudyData?.slug,
        ...caseStudyData.acf_json,
    }

    console.log(data)

    const renderItems = () => {
        if (!data.intro_information) return

        return data.intro_information.map((item, i) => {
            return (
                <Item key={i}>
                    <Subheading>{item.value}</Subheading>
                </Item>
            )
        })
    }
    
    return (
        <Wrapper>
            <Grid>
                <Left>
                    {data.hero_image && (
                        <Image
                            key={data.hero_image.id}
                            src={data.hero_image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage 
                                        image={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}
                </Left>
                <Right>
                    <Text>
                        <Heading>Case Study</Heading>
                        <Items>
                            {data.title && (
                                <Item>
                                    <Subheading>
                                        {data.title}
                                    </Subheading>
                                </Item>
                            )}
                            {renderItems()}
                        </Items>

                        <Button
                            as={Link}
                            to={`/casestudies/${data.slug}`}
                        >
                            View full case study
                        </Button>
                    </Text>
                </Right>
            </Grid>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Items = styled.div``
const Item = styled.div``
const Button = styled.div`
    ${button}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`


const Wrapper = styled.div`
    ${tw`w-full border border-blue`};
`

const Text = styled.div``
const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}

    ${Left} {
        width: 50%;
        width: calc(50% + 2rem);

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Right} {
        flex: 1;

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${BGImage} {
        ${tw`w-full h-full`};

        ${media.mob`
            height: 250px;
        `}
    }

    ${Text} {
        ${tw`flex flex-col items-start p-16`};

        ${media.mob`
            padding: 2.5rem;
        `}
    }

    ${Heading} {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.25;
    }

    ${Subheading} {
        font-size: 1rem;
        line-height: 1;
    }

    ${Items} {
        ${tw`flex flex-col items-start w-full`};

        ${Item} {
            ${tw`w-full border-b border-blue`};
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &:first-child {
                ${tw`border-t`};
            }
        }
    }

    ${Button} {
        margin-top: 12rem;
        font-size: 1rem;

        ${media.mob`
            margin-top: 4rem;
        `}

        &:hover {
            ${tw`bg-blue text-grey`};
        }
    }
`

BlockCaseStudy.wrapper = css``

export default BlockCaseStudy
