import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'

const Grid = props => {
    const { columns, items } = props

    const renderItems = () => {
        return items.map((item, i) => {
            return (
                <Item
                    className={'grid-item'}
                    key={i}
                >
                    {item}
                </Item>
            )
        })
    }

    return (
        <Items
            className={'grid-items'}
            columns={columns}
        >
            {items && renderItems()}
        </Items>
    )
}


const Item = styled.div``
const Items = styled.div`
    ${tw`flex flex-wrap -mx-4`};

    ${media.mob`
        ${tw`flex-col mx-0`};
    `}

    ${Item} {
        ${tw`flex flex-col w-1/3 px-4 mb-8`};

        ${media.tabPor`
            ${tw`w-1/2`};
        `}

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }
`

export default Grid
