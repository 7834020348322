import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockVideo = props => {
    const { video } = props
    if (!video) return

	const getVideoURL = (video) => {
        if (!video) return false
		if (video.match(/src="(.+?)"/)) {
			return video.match(/src="(.+?)"/)[1]
		} else {
            return video
        }
	}

    return (
        <Wrapper>
            <VideoWrapper>
                <Video url={getVideoURL(video)} />
            </VideoWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

// Video

const Video = styled(ReactPlayer)`
    width: 100%!important;
`
const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    ${Video} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%!important;
        height: 100%!important;
    }
`

BlockVideo.wrapper = css``

export default BlockVideo
