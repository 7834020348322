import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { filter } from 'lodash'

import { parseACF } from '../utils'
import { Layout, PageHeader, Grid, Pagination } from '../components'
import { media } from '../styles/utils'
import { container, padding, type, bgImage, button } from '../styles/global'
import CardImage from '../components/CardImage';

const NewsListing = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext?.data
    const { group, index, first, last, pageCount } = props.pageContext
    const [activeTopic, setActiveTopic] = useState(false)
    const [gridData, setGridData] = useState([])
    // console.log(index)
    // console.log(activeTopic)

    if (typeof group !== 'undefined') {
        group.forEach((item, i) => (item.acf = JSON.parse(item.acf_json)))
    }

    // Mount
    useEffect(() => {
        setGridData(group)
    }, [])

    // Mount
    useEffect(() => {
        if (activeTopic && data.news) {
            setGridData(data.news)
            if (activeTopic !== 'all') {
                filterGrid()
            }
        }

        if (!activeTopic && group) {
            setGridData(group)
        }
    }, [activeTopic])

    const filterGrid = () => {
        let results = data?.news

        if (activeTopic) {
            results = filter(results, item => {
                let hasTag = false

                if (item.cats) {
                    if (item.cats.slug === activeTopic) {
                        hasTag = true
                    }
                }

                return hasTag
            })
        }

        setGridData(results)
    }

    const renderTopics = () => {
        if (!data.topics) return

        const items = data.topics.map((item, i) => {
            return (
                <Item
                    key={i}
                    onClick={() => {
                        if (activeTopic === item.slug) {
                            setActiveTopic(false)
                        } else {
                            setActiveTopic(item.slug)
                        }
                    }}
                    className={activeTopic === item.slug && 'active'}
                    dangerouslySetInnerHTML={{
                        __html: item.title,
                    }}
                />
            )
        })

        return (
            <Topics>
                <Heading>Topics</Heading>
                <Items>
                    <Item
                        onClick={() => {
                            if (activeTopic === 'all') {
                                setActiveTopic(false)
                            } else {
                                setActiveTopic('all')
                            }
                        }}
                        className={activeTopic === 'all' && 'active'}
                    >
                        All
                    </Item>
                    {items}
                </Items>
            </Topics>
        )
    }

    const renderArticles = () => {
        const items = gridData.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/news/${item.slug}`}>
                    {item.acf.tile_icon && (
                        <ProgressiveImage
                            key={item.acf.tile_icon.sizes.medium2}
                            src={item.acf.tile_icon.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <CardImage
                                        padding={'76.5%'}
                                        src={src}
                                        styles={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </ProgressiveImage>
                    )}
                    <Text>
                        {item.title && (
                            <Heading>{item.title}</Heading>
                        )}
                        {item.acf.tile_description && (
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: item.acf.tile_description,
                                }}
                            />
                        )}
                        <Button>
                            Read more
                        </Button>
                    </Text>
                </Item>
            )
        })

        return (
            <Articles>
                {gridData.length > 0 && (
                    <Grid
                        items={items}
                    />
                )}
                {gridData.length === 0 && (
                    <Heading>No results found</Heading>
                )}
            </Articles>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={'News'} />

                    {renderTopics()}

                    {renderArticles()}

                    {!activeTopic && pageCount > 1 && (
                        <Pagination
                            slug={`news`}
                            index={index}
                            first={first}
                            last={last}
                            pageCount={pageCount}
                        />
                    )}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled.div``

const Button = styled.div`
    ${button}
`

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Topics

const Topics = styled.div`
    ${tw`flex`};
    margin-bottom: -1rem;

    ${Heading} {
        font-size: 2rem;
        margin-right: 2rem;
    }

    ${Items} {
        ${tw`flex flex-wrap`};
        margin: 0 -0.5rem;
    }

    ${Item} {
        margin: 0 0.5rem 1rem;
        ${button}
        font-size: 1rem;
        
        &.active {
            ${tw`bg-blue text-grey`};
        }

        @media (min-width: 1025px) {
            &:hover {
                ${tw`bg-blue text-grey`};
            }
        }
    }
`


// Articles

const Text = styled.div``
const Articles = styled.div`
    margin-top: 5rem;
    margin-bottom: 5rem;

    ${media.mob`
        margin-bottom: 6rem;
    `}

    ${Item} {
        ${tw`flex flex-col border border-blue`};
        flex-grow: 1;

        ${Text} {
            ${tw`flex flex-col items-center w-full p-8 border-t border-blue`};
            flex-grow: 1;
        }
    }

    ${Heading} {
        ${tw`w-full`};
        font-size: 1.5rem;
    }

    ${Description} {
        margin-top: 1.75rem;
        margin-bottom: 3rem;
        font-size: 1rem;
    }

    ${Button} {
        margin-top: auto;
        ${button}
        font-size: 1rem;
        
        &:hover {
            ${tw`bg-blue text-grey`};
        }
    }
`

export default NewsListing
