import React, { useState, useRef, useContext } from 'react'
import { navigate } from 'gatsby'
import { useMount, useUnmount } from 'react-use'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { container, padding, type } from '../../styles/global'
import { navigation } from '../../styles/type'
import { headerHeight } from '../Header/Header'

import { MobileMenuContext } from '../MobileMenu/context'

const MobileMenu = props => {
    const { menu } = props
    const mobileMenuEl = useRef(null)
    const mobileMenu = useContext(MobileMenuContext)

    console.log(menu)

    useMount(() => disableBodyScroll(mobileMenuEl.current))

    useUnmount(() => clearAllBodyScrollLocks())

    const renderMenu = () => {
        if (!menu) return
        return menu.map((item, i) => {
            const link = item?.link
            if (!link) return

            if (link.type === 'internal') {
                if (link?.url.includes('news-page')) return false
                return (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            navigate(link?.url)
                            mobileMenu.toggleActive()
                        }}
                        activeClassName={'active'}
                        cta={item?.cta}
                    >
                        {link?.title}
                    </MenuItem>
                )
            } else {
                return (
                    <ExternalLink
                        key={i}
                        href={link?.url}
                        cta={link?.cta}
                    >
                        {link?.title}
                    </ExternalLink>
                )
            }
        })
    }

    return (
        <Wrapper ref={mobileMenuEl}>
            <Container>
                <Menu>
                    {renderMenu()}
                </Menu>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`w-full h-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const ExternalLink = styled.a`
    ${props => {
        if (props.cta)
            return css`
                color: #EA642B!important;
            `
    }}
`
const MenuItem = styled.div`
    ${props => {
        if (props.cta)
            return css`
                color: #EA642B!important;
            `
    }}
`
const Menu = styled.div`
    ${tw`flex flex-col items-center pt-32 pb-8`};

    ${MenuItem}, ${ExternalLink} {
        ${tw`inline-block my-6 text-blue`};
        ${type.headerLinks};
        font-size: 2rem;
    }
`

export default MobileMenu
