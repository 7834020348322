import React from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

const HeroImage = props => {
    const { image } = props
    if (!image) return

    return (
        <Wrapper>
            <Image
                key={image.id}
                src={image.sizes.medium2}
            >
                {(src, loading) => {
                    return (
                        <LoadedImage
                            src={src} 
                            style={{opacity: loading ? 0 : 1}}  
                        />
                    )
                }}
            </Image> 
        </Wrapper>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-bottom: 4rem;
`

export default HeroImage
