import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { parseACF } from '../utils'
import { Layout, PageHeader, Grid } from '../components'
import { media } from '../styles/utils'
import { container, padding, type, bgImage, button } from '../styles/global'

const Services = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log(data)

    const renderServices = () => {
        if (!data.services) return

        const items = data.services.map((item, i) => {
            return (
                <Item key={i} as={Link} to={`/services/${item.slug}`}>
                    {item.acf.tile_icon && (
                        <Image
                            key={item.acf.tile_icon.sizes.medium2}
                            src={item.acf.tile_icon.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <Icon 
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image>
                    )}
                    {item.title && (
                        <Heading>{item.title}</Heading>
                    )}
                    {item.acf.tile_description && (
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.acf.tile_description,
                            }}
                        />
                    )}
                    <Button>
                        Find out more
                    </Button>
                </Item>
            )
        })

        return (
            <Items>
                <Grid
                    items={items}
                />
            </Items>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
        >
            <Wrapper>
                <Container>
                    <PageHeader title={'Services'} subheading={data?.intro_subheading} text={data?.intro_text} />
                    {renderServices()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const Icon = styled.img`
    transition: opacity 0.45s ease;
`
const Button = styled.div`
    ${button}
`

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`


// Items

const Item = styled.div``
const Items = styled.div`
    margin-bottom: 9rem;

    ${media.mob`
        margin-bottom: 6rem;
    `}

    ${Item} {
        ${tw`flex flex-col items-center p-12 text-center border border-blue`};
        flex-grow: 1;

        ${Icon} {
            max-height: 6rem;
            max-width: 10rem;
        }

        ${Heading} {
            ${tw`mt-10 font-medium`};
            font-size: 1.5rem;
        }

        ${Description} {
            ${tw`py-5`};
            font-size: 1rem;
            margin-bottom: 1rem;

            ${media.mob`
                margin-bottom: 2rem;
            `}
        }

        ${Button} {
            margin-top: auto;
            font-size: 1rem;
        
            &:hover {
                ${tw`bg-blue text-grey`};
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "services-page" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Services
