import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { findIndex } from 'lodash'
import { getLastTextIndexes } from '../utils';

import { Layout, PageHeader, HeroImage, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, bgIcon, maxWidth, button } from '../styles/global'

const CaseStudiesSingle = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext.acf
    const title = pageContext?.title || pageContext?.data?.title
    console.log(data)

	const renderBlocks = () => {
        if (!data.content_blocks) return;
        const lastTextBlocks = getLastTextIndexes(data.content_blocks)

		return data.content_blocks.map((block, i) => {
            let isLastTextBlock = false
            for (let index = 0; index < lastTextBlocks.length; index++) {
                if (i === lastTextBlocks[index]) {
                    isLastTextBlock = true
                }
            }
			return (
				<Block
                    key={i}
                    layout={block?.acf_fc_layout}
                    lastTextBlock={isLastTextBlock}
                    lastBlock={i+1 === data.content_blocks.length}
					{...block}
                />
			)
		})
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Container>
                    <PageHeader title={title} isCaseStudy={true} info={data?.intro_information} />
                    {data.hero_image && <HeroImage image={data?.hero_image} />}
                    {renderBlocks()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div`
    ${tw`font-medium`};
    font-size: 2.5rem;
`
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Items = styled.div``
const Item = styled.div``
const ExternalLink = styled.a``
const Button = styled.div`
    ${button}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
`

const Container = styled.div`
    ${container};
    ${padding};
`

export default CaseStudiesSingle
