import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../styles/utils'
import { container, margins, padding, hoverState, type } from '../../styles/global'

export const announcementHeight = '3rem'

const AnnouncementBar = ({data}) => {
    const { text, includeLink, link } = data
    console.log(text)

    return (
        <Wrapper>
            <Container
                as={includeLink && Link}
                to={includeLink && link && link}
            >
                {text && (
                    <Text
                        className={'tiny-mce'}
                        dangerouslySetInnerHTML={{
                            __html: `${text}`,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`z-20 fixed top-0 inset-x-0 w-full bg-grey`};
`

const Container = styled.div`
    ${container}
    ${padding}
    ${tw`flex justify-center items-center text-center`};
    height: ${announcementHeight};
    overflow-y: auto;

    ${media.tab`
        ${tw`justify-start`};
    `}
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
    ${tw`text-center text-blue`}
    line-height: 1!important;
    white-space: nowrap;

    ${media.tab`
        ${tw`text-left`};
        
        > *:last-child {
            ${margins}
            margin-left: 0!important;
        }
    `}
`

export default AnnouncementBar
