import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { GoogleMap } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockGoogleMap = props => {
    const { heading, content, lastBlock, lastTextBlock, google_map, page } = props
    console.log(props)

    return (
        <Wrapper lastBlock={lastBlock} lastTextBlock={lastTextBlock} page={page}>
            <Grid>
                <Left>
                    {heading && <Heading>{heading}</Heading>}
                </Left>
                <Right>
                    {content && (
                        <Text
                            className={'tiny-mce'}
                            dangerouslySetInnerHTML={{
                                __html: `${content}`,
                            }}
                        />
                    )}
                    <MapWrapper>
                        <GoogleMap
                            map={google_map}
                        />
                    </MapWrapper>
                </Right>
            </Grid>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full border-b border-blue`};
    padding-bottom: 4rem;

    ${props => {
        if (props.lastBlock || props.lastTextBlock)
            return css`
                padding-bottom: 0;
                border-bottom: none;
            `
    }}

    ${props => {
        if (props.page === 'contact-us')
            return css`
                padding-top: 4rem;
                ${tw`border-t`};
            `
    }}
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.mob`
        ${tw`block mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            margin-top: 3rem;
        `}
    }
`

// Text

const Heading = styled.div`
    font-size: 1.5rem;
    line-height: 1.25;
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

// Map

const MapWrapper = styled.div`
    ${tw`relative w-full overflow-hidden`};
    height: 0;
    padding-bottom: 76.1643836%;
`


BlockGoogleMap.wrapper = css``

export default BlockGoogleMap
