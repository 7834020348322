import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockAudio = props => {
    const { audio } = props
    if (!audio) return
    console.log(audio)

    useEffect(() => {
        getAudioURL(audio)
    }, [])

	const getAudioURL = (audio) => {
        if (!audio) return false
		if (audio.match(/&"(.+?)"/)) {
            return audio
		} else {
            return audio + '&color=0C1C20'
        }
	}

    return (
        <Wrapper>
            <AudioWrapper>
                <Audio
                    url={getAudioURL(audio)}
                />
            </AudioWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

// Audio

const Audio = styled(ReactPlayer)`
    width: 100% !important;
    height: 166px!important;
`
const AudioWrapper = styled.div`

`

BlockAudio.wrapper = css``

export default BlockAudio
