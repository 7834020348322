import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { renderLink } from '../../../utils'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, button } from '../../../styles/global'

import downloadIcon from '../../../assets/images/icons/download.svg'

const BlockDownload = props => {
    const { title, description, file, download_text, image } = props
    console.log(props)

    return (
        <Wrapper>
            <Grid>
                <Left>
                    {image && (
                        <Image
                            key={image?.id}
                            src={image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}
                </Left>

                <Right>
                    {title && (
                        <Heading>
                            {title}
                        </Heading>
                    )}
                    {description && (
                        <Description>
                            {description}
                        </Description>
                    )}
                    {file && (
                        <Button
                            as={ExternalLink}
                            href={file}
                            target="_blank"
                        >
                            {download_text ? download_text : 'Download'}
                        </Button>
                    )}
                </Right>
            </Grid>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const ExternalLink = styled.a``
const Button = styled.div``
const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full bg-blue text-grey`};
    padding-top: 4rem;
    padding-bottom: 4rem;
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex items-center w-full`};

    ${media.mob`
        ${tw`block`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2`};
    }

    ${Left} {
        ${tw`flex flex-col items-center`};
        width: calc(50% + 2rem);

        ${media.mob`
            ${tw`w-full px-10`};
        `}

        ${LoadedImage} {
            height: 320px;
            width: auto;

            ${media.mob`
                ${tw`w-full`};
                height: auto;
            `}
        }
    }

    ${Right} {
        flex: 1;
        ${tw`flex flex-col items-start px-16`};

        ${media.mob`
            ${tw`px-10`};
        `}

        ${media.mob`
            ${tw`w-full`};
            margin-top: 3rem;
        `}

        ${Heading}, ${Description} {
            ${type.body}
        }

        ${Description} {
            margin-top: 1.5rem;
        }

        ${Button} {
            margin-top: 2.5rem;
            ${button}
            ${tw`border-grey text-grey`};
            

            &:hover {
                ${tw`bg-grey border-blue text-blue`};
            }
        }
    }
`



BlockDownload.wrapper = css``

export default BlockDownload
