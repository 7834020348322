import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockTextMedia = props => {
    const { heading, content, lastBlock, lastTextBlock, include_media, media_type, image, video } = props
    console.log(media_type)

	const getVideoURL = (video) => {
        if (!video) return false
		if (video.match(/src="(.+?)"/)) {
			return video.match(/src="(.+?)"/)[1]
		} else {
            return video
        }
	}

    return (
        <Wrapper lastBlock={lastBlock} lastTextBlock={lastTextBlock}>
            <Grid>
                <Left>
                    {heading && <Heading>{heading}</Heading>}
                </Left>
                <Right>
                    {content && (
                        <Text
                            className={'tiny-mce'}
                            dangerouslySetInnerHTML={{
                                __html: `${content}`,
                            }}
                        />
                    )}
                    {include_media && media_type === 'image' && image && (
                        <Image
                            key={image.id}
                            src={image.sizes.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src} 
                                        style={{opacity: loading ? 0 : 1}}  
                                    />
                                )
                            }}
                        </Image> 
                    )}
                    {include_media && media_type === 'video' && video && (
                        <VideoWrapper>
                            <Video url={getVideoURL(video)} />
                        </VideoWrapper>
                    )}
                </Right>
            </Grid>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full border-b border-blue`};
    padding-bottom: 4rem;

    ${props => {
        if (props.lastBlock || props.lastTextBlock)
            return css`
                padding-bottom: 0;
                border-bottom: none;
            `
    }}
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex -mx-8`};

    ${media.mob`
        ${tw`block mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-8`};

        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            margin-top: 3rem;
        `}
    }
`

// Text

const Heading = styled.div`
    font-size: 1.5rem;
    line-height: 1.25;
`

export const Text = styled.div`
    /* check global .tiny-mce styles */
`

// Image

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const LoadedImage = styled.img`
    margin-top: 3rem;
    transition: opacity 0.45s ease;
`


// Video

const Video = styled(ReactPlayer)`
    width: 100%!important;
`
const VideoWrapper = styled.div`
    margin-top: 3rem;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    ${Video} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%!important;
        height: 100%!important;
    }
`



BlockTextMedia.wrapper = css``

export default BlockTextMedia
