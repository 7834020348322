import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { MobileMenu } from '../'
import { MobileMenuContext } from '../MobileMenu/context'
import { getGlobalData } from '../../utils'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { renderLink } from '../../utils'
import SEO from'../seo'
import logo from '../../assets/images/logos/accumulus-advisory-logo.png'

export const headerHeight = '6.25rem'
import { announcementHeight } from '../AnnouncementBar/AnnouncementBar'

const Header = props => {
    const { announcementDisplay } = props
    const { global: data } = getGlobalData()
    const mobileMenu = useContext(MobileMenuContext)
    // console.log(data)

    const renderMenuItems = menu => {
        if (!menu) return
        return menu.map((item, i) => {
            const link = item?.link
            if (!link) return
            return renderLink(link, i)
        })
    }

    return (
        <>
            {/* Mobile Menu */}
            <AnimatePresence>
                {mobileMenu.active && (
                    <MobileMenuWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MobileMenu
                            menu={
                                [...data?.header_nav, {link: {cta: true, ...data?.header_cta}}]
                            }
                        />
                    </MobileMenuWrapper>
                )}
            </AnimatePresence>

            {/* Desktop Menu */}
            <Wrapper
                announcementDisplay={announcementDisplay}
            >
                <Container>
                    <Grid>
                        <Left>
                            <HomeLink
                                onClick={() => {
                                    navigate('/')
                                    if (mobileMenu.active) {
                                        mobileMenu.toggleActive()
                                    }
                                }}
                            >
                                {data.header_logo && (
                                    <Logo src={data.header_logo.sizes.full_width} />
                                )}
                                {!data.header_logo && (
                                    <Logo src={logo} />
                                )}
                            </HomeLink>
                        </Left>

                        {/* Desktop Only */}
                        <Menu>{renderMenuItems(data?.header_nav)}</Menu>

                        {data.header_cta && (
                            <CTA>
                                {renderLink(data.header_cta, 0)}
                            </CTA>
                        )}

                        {/* Tablet Only */}
                        <MobileNavToggle
                            onClick={() => {
                                mobileMenu.toggleActive()
                            }}
                        >
                            {!mobileMenu.active && (
                                <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                            {mobileMenu.active && (
                                <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </MobileNavToggle>
                    </Grid>
                </Container>
            </Wrapper>
        </>
    )
}

// Layout

const Wrapper = styled.header`
    ${tw`z-20 fixed top-0 inset-x-0 w-full bg-blue`};

    ${props => {
        if (props.announcementDisplay)
            return css`
                top: ${announcementHeight};
            `
    }}
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    height: ${headerHeight};
    ${tw`flex justify-between items-center w-full`};

    ${Left}, ${Right} {
        ${tw`flex`}
    }
`

// Logo

const Logo = styled.img``

const HomeLink = styled.div`
    height: 4.25rem;
    cursor: pointer;

    ${Logo} {
        ${tw`h-full`};
        margin-right: 0.6rem;
        width: auto;
    }
`

// Menu

const menuLink = css`
    ${tw`inline-block mx-5 text-grey cursor-pointer`};
    ${type.headerLinks};
    ${hoverState};

    &:last-child {
        ${tw`mr-0`};
    }
`

const MenuItem = styled.a``
const Menu = styled.div`
    ${tw`flex`};

    ${media.tab`
        display: none;
    `}

    .link, a {
        ${menuLink};
    }
`

// CTA

const CTA = styled.div`
    .link, a {
        ${menuLink};
        ${tw`text-orange`};
    }

    ${media.tab`
        display: none;
    `}
`

// Mobile Nav Toggle

const MobileNavToggle = styled.div`
    display: none;
    ${type.headerLinks};
    ${tw`text-grey`};

    ${media.tab`
        ${tw`block`};
    `}
`

// Mobile Menu

const MobileMenuWrapper = styled(motion.div)`
    ${tw`z-20 fixed inset-0 w-full h-full bg-grey`};
    overflow-y: auto;
    top: ${headerHeight};
`

export default Header
