import React, { useContext, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { MetaTags, Footer, Block, Header, AnnouncementBar } from '../'
import { media } from '../../styles/utils'
import { container } from '../../styles/global'
import { headerHeight } from '../Header/Header'

const Layout = ({ children, meta, announcementDisplay, announcementData }) => {
    return (
        <>
            <MetaTags {...meta} />
            <Wrapper>
                <Transition>
                    {announcementDisplay && (
                        <AnnouncementBar
                            data={announcementData}
                        />
                    )}
                    <Header
                        announcementDisplay={announcementDisplay}
                    />
				    {children}
                    <Footer/>
                </Transition>
            </Wrapper>
        </>
    )
}


const Wrapper = styled.div`
    ${tw`w-full h-full`};
    padding-top: ${headerHeight};
`

export default Layout
