import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { SignupForm } from '../../'
import { media } from '../../../styles/utils'
import { container, padding, type } from '../../../styles/global'

const BlockSignup = props => {
    return (
        <Wrapper>
            <FormWrapper>
                <SignupForm />
            </FormWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${tw`w-full`};
`

const FormWrapper = styled.div`
    ${tw`w-full`};
`

BlockSignup.wrapper = css``

export default BlockSignup
