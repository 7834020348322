import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;
    transition: opacity 0.45s ease;
    padding-bottom: ${props => props.padding ? props.padding : '100%'};
`;

const Image = styled.img`
    position: absolute;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

const CardImage = ({ className, styles, src, padding }) => {
    return (
        <Wrapper style={styles} className={className} padding={padding}>
            <Image src={src} style={{ height: '100%' }} />
        </Wrapper>
    )
};

export default CardImage;